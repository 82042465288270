import { render, staticRenderFns } from "./sampling.vue?vue&type=template&id=4d78fef6&"
import script from "./sampling.vue?vue&type=script&lang=js&"
export * from "./sampling.vue?vue&type=script&lang=js&"
import style0 from "./sampling.vue?vue&type=style&index=0&id=4d78fef6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports